let lorems=[
    "Sit sunt ullamco dolor sit. Magna cupidatat commodo voluptate in est laboris tempor ad consectetur. Duis magna ipsum pariatur excepteur.",
    "Officia esse do aute sit. Sit exercitation irure excepteur laborum officia in cupidatat aute proident.",
    "Sint incididunt dolore exercitation magna aute ad aliquip ex officia. Elit consectetur exercitation nisi pariatur minim reprehenderit duis est nostrud. Esse eu reprehenderit commodo.",
    "Nulla reprehenderit elit id aute adipisicing ut proident eu. Ea exercitation magna culpa proident qui anim. Dolore irure qui eu laborum id do labore mollit esse.",
    "Ut Lorem sunt quis exercitation ad occaecat. Proident aliqua nulla adipisicing.",
    "Excepteur est voluptate culpa do laborum. Laboris aute do laboris eiusmod tempor.",
    "Ipsum ex laborum deserunt ad consectetur ex aliqua. Eu excepteur excepteur ut aute. Commodo consectetur est mollit ullamco mollit sunt.",
    "Voluptate duis quis proident. Officia consectetur excepteur deserunt adipisicing esse officia tempor minim incididunt.",
    "Ad tempor voluptate consequat id do pariatur. Nulla elit reprehenderit eu anim enim sit ullamco ad enim. Dolor ad adipisicing nostrud reprehenderit proident. Est quis do ut ullamco irure officia fugiat quis anim.",
    "Laborum aliqua pariatur id officia. Lorem duis non et nulla sit id eiusmod est. Adipisicing culpa minim et eiusmod cupidatat aliqua excepteur.",
    "Aute sint magna dolore magna do. Laborum Lorem culpa Lorem. Officia qui magna irure cillum. Anim occaecat labore est.",
    "Do anim commodo ut laboris labore. Dolor proident laboris sunt sit proident Lorem.",
    "Nulla adipisicing eiusmod magna consequat sit tempor labore non minim. Ex nostrud ad sit. Aute in velit Lorem dolor Lorem velit aliquip minim.",
    "Dolore proident nulla culpa. Qui pariatur id quis elit ullamco adipisicing. Labore dolor tempor ullamco tempor tempor. Lorem anim labore do est veniam eu officia non.",
    "Excepteur reprehenderit occaecat ex ex nulla mollit ullamco laboris dolor. Amet ipsum sit eu Lorem. Voluptate nulla nulla officia eu excepteur excepteur. Reprehenderit nisi duis eiusmod dolor ex Lorem enim excepteur ullamco.",
    "Culpa laboris minim excepteur ut non Lorem commodo veniam. Consequat voluptate enim culpa enim nulla. Dolore in consectetur Lorem excepteur aute esse. Exercitation cupidatat cupidatat veniam Lorem deserunt amet velit esse commodo.",
    "Irure ullamco cillum dolore quis est ipsum exercitation est tempor. Dolor reprehenderit magna velit duis. Irure cupidatat fugiat ea et proident officia culpa proident. Cillum anim proident nostrud ipsum incididunt.",
    "Ipsum non laboris id est cupidatat est deserunt. Dolore cillum eiusmod irure.",
    "Amet sit eu duis. Et laboris aliquip do dolor labore laboris ullamco.",
    "Irure cillum occaecat officia voluptate velit ad. Minim consectetur ex adipisicing cupidatat ea occaecat. Consectetur elit id aliquip."
]

module.exports = lorems